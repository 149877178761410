<template>
  <div>
    <div v-if="this.$route.name === 'Supplier'">
      <Supplier />
    </div>
    <router-view :key="$route.fullPath"/>
  </div>
</template>
<script>
import Supplier from "@/views/companyApp/supplier/Supplier.vue";

export default {
  name: 'SupplierParent',
  components: {
    Supplier
  }
}
</script>
